import React, { useState, useEffect, useRef } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Incomplete Applications.css";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button } from "react-bootstrap";
import Heading from "../Heading/Heading";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import {
  getIncomplete_Application_API,
  deleteIncomplete_Application_API,
  getIncomplete_Application_path_for_routing_API,
} from "../../api";
import Loader from "../Loader/Loader";

const IncompleteApplications = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);

  // const [dataPerPageButton, setDataPerPageButton] = useState("10");
  // const [searchQuery, setSearchQuery] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);

  // Initialize state from localStorage or default values
  const [dataPerPageButton, setDataPerPageButton] = useState(() => {
    const storedValue = localStorage.getItem(
      "incompleteApplication_dataPerPageButton"
    );
    return storedValue ? JSON.parse(storedValue) : "10";
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const storedValue = localStorage.getItem(
      "incompleteApplication_currentPage"
    );
    return storedValue ? JSON.parse(storedValue) : 1;
  });

  const [searchQuery, setSearchQuery] = useState(() => {
    const storedValue = localStorage.getItem(
      "incompleteApplication_searchQuery"
    );
    return storedValue || "";
  });

  // Update localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem(
      "incompleteApplication_dataPerPageButton",
      JSON.stringify(dataPerPageButton)
    );
  }, [dataPerPageButton]);

  useEffect(() => {
    localStorage.setItem(
      "incompleteApplication_currentPage",
      JSON.stringify(currentPage)
    );
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("incompleteApplication_searchQuery", searchQuery);
  }, [searchQuery]);

  const [deletingPersonName, setDeletingPersonName] = useState("");
  const [ExtraData, setExtraData] = useState();

  const [logoutClicked, setLogoutClicked] = useState(false);

  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [PointerID, setPointerID] = useState(null);

  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

  const [currentPageData, setCurrentPageData] = useState([]);

  const getIncompleteApplicationsFunction = async (data) => {
    try {
      setLoading(true);

      const response = await getIncomplete_Application_API(data);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data &&
        response?.data?.response?.data?.page_data
      ) {
        setExtraData(response?.data?.response?.data);
        setCurrentPageData(response?.data?.response?.data?.page_data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  const fetchIncompleteApplications = () => {
    const formDataClearApplicationsRecods = new FormData();
    formDataClearApplicationsRecods.append("search_input", searchQuery);
    formDataClearApplicationsRecods.append("search_flag", "");
    formDataClearApplicationsRecods.append("itemsPerPage", dataPerPageButton);
    formDataClearApplicationsRecods.append("page", currentPage);

    getIncompleteApplicationsFunction(formDataClearApplicationsRecods);
  };
  useEffect(() => {
    fetchIncompleteApplications();
  }, []);

  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Dashboard.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/dashboard");


  };

  const handleConfirmationDeleteNo = () => {
    setShowConfirmationDeleteModal(false);
  };

  const handleDelete = (pointer_id, fullName) => {
    setDeleteButtonClicked(true);
    setShowConfirmationDeleteModal(true);
    setPointerID(pointer_id);
    setDeletingPersonName(fullName);
  };

  const handleConfirmationDeleteYes = async () => {
    try {
      setLoading(true);

      const response = await deleteIncomplete_Application_API(PointerID);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        setShowConfirmationDeleteModal(false);
        fetchIncompleteApplications();
        localStorage.removeItem("active_candidate_name");
        toast.success("Application Deleted successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to delete Incomplete Application"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to delete Incomplete Application");
    }
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };
  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handleEdit = async (pointer_id, applicant_name) => {
    setPointerID(pointer_id);

    localStorage.setItem("active_candidate_name", applicant_name);

    try {
      setLoading(true);

      const response = await getIncomplete_Application_path_for_routing_API(
        pointer_id
      );

      setLoading(false);

      if (response?.data?.response?.response === true) {
        const pathFromAPI = response?.data?.response?.route?.path;
        const pointerData = response?.data?.response?.route?.pointerData;

        navigate(pathFromAPI, {
          state: { pointerData },
        });
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to route in your Incomplete Application"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to route in your Incomplete Application");
    }
  };













  const totalPages = Math.ceil(ExtraData?.totalRows / dataPerPageButton);
  const visiblePages = 4;

  const [startPage, setStartPage] = useState(() => {
    const savedStartPage = localStorage.getItem(
      "startPage_agent_incompleteApplications"
    );
    return savedStartPage ? Number(savedStartPage) : 1;
  });

  useEffect(() => {
    fetchPageData(currentPage);
    // Update local storage when currentPage or startPage changes
    // localStorage.setItem('incompleteApplication_currentPage', currentPage);
    localStorage.setItem("startPage_agent_incompleteApplications", startPage);
  }, [currentPage, startPage]);

  const fetchPageData = (pageNumber) => {
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchQuery);
    formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
    formDataApplicationsRecords.append("page", pageNumber);

    // Your data fetching function
    getIncompleteApplicationsFunction(formDataApplicationsRecords);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFirst = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const handleLast = () => {
    const lastPage = totalPages;
    setCurrentPage(lastPage);
    const newStartPage = Math.max(1, lastPage - visiblePages + 1);
    setStartPage(newStartPage);
  };

  const handlePrev = () => {
    if (startPage > 1) {
      const newStartPage = Math.max(1, startPage - visiblePages);
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };

  const handleNext = () => {
    if (startPage + visiblePages <= totalPages) {
      const newStartPage = startPage + visiblePages;
      setStartPage(newStartPage);
      setCurrentPage(newStartPage);
    }
  };





  // const handlePrevious = () => {
  //   // Only decrement the current page if it's greater than 1
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }

  //   // Determine the previous page number, ensuring it doesn't go below 0
  //   const currentPreviousPageNo = currentPage > 1 ? currentPage - 1 : 0;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("search_flag", "");
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentPreviousPageNo);

  //   // Call the function to get the application manager data
  //   getIncompleteApplicationsFunction(formDataApplicationsRecords);
  // };

  // const handleNext = () => {
  //   setCurrentPage(currentPage + 1);

  //   const currentNextPageNo = currentPage + 1;

  //   // Prepare form data
  //   const formDataApplicationsRecords = new FormData();
  //   formDataApplicationsRecords.append("search_input", searchQuery);
  //   formDataApplicationsRecords.append("search_flag", "");
  //   formDataApplicationsRecords.append("itemsPerPage", dataPerPageButton);
  //   formDataApplicationsRecords.append("page", currentNextPageNo);

  //   getIncompleteApplicationsFunction(formDataApplicationsRecords);
  // };

























  const handleSearch = (event) => {

    setStartPage(1);
    localStorage.setItem("startPage_agent_incompleteApplications", 1);


    setSearchQuery(event.target.value);
    const searchInputByUser = event.target.value;

    // Prepare form data
    const formDataApplicationsRecords = new FormData();
    formDataApplicationsRecords.append("search_input", searchInputByUser);
    formDataApplicationsRecords.append("search_flag", "");
    formDataApplicationsRecords.append("itemsPerPage", 10);
    formDataApplicationsRecords.append("page", 1);

    // Debounce the API call
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getIncompleteApplicationsFunction(formDataApplicationsRecords);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    const rowLength = event.target.value;
    const formDataApplicationsRecods = new FormData();
    formDataApplicationsRecods.append("search_input", searchQuery);
    formDataApplicationsRecods.append("search_flag", "");
    // formDataApplicationsRecods.append("itemsPerPage", rowLength);
    // formDataApplicationsRecods.append("page", currentPage);

    formDataApplicationsRecods.append("itemsPerPage", rowLength);
    formDataApplicationsRecods.append("page", 1);

    localStorage.setItem("incompleteApplication_currentPage", 1);
    setCurrentPage(1);

    localStorage.setItem("startPage_agent_incompleteApplications", 1);
    setStartPage(1);

    getIncompleteApplicationsFunction(formDataApplicationsRecods);
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to Dashboard
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Incomplete Applications" />
      </Row>

      {/* {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}

      {loading && <Loader />}

      <div className="incomplete-info">
        <Card className="shadow forgot-card-identification">
          <Card.Body>
            <div className="account-details">
              <div id="table_wrapper" className="dataTables_wrapper no-footer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="dataTables_lengthIn">
                    <label>
                      <select
                        value={dataPerPageButton}
                        onChange={handleDataPerPageChange}
                        style={{ cursor: "pointer" }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>

                  <div id="table_filter" className="dataTables_filter">
                    <label>
                      <i className="fa fa-search"></i>
                      <input
                        type="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                </div>

                {currentPageData?.length === 0 && !loading && (
                  <div className="no-data-message">
                    <p className="oops-message">Oops! No data to display.</p>
                  </div>
                )}

                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-striped table-hover dataTable no-footer">
                          <thead>
                            <tr>
                              <th scope="col" class="col-2">
                                PRN
                              </th>
                              <th scope="col" class="col-3">
                                Applicant Name
                              </th>
                              <th scope="col" class="col-2">
                                D.O.B
                              </th>
                              <th scope="col" class="col-2">
                                Occupation
                              </th>
                              <th scope="col" class="col-2">
                                Date Created
                              </th>
                              <th scope="col" class="col-1">
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {currentPageData.map((item, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td>
                                  <strong>{item?.prn}</strong>
                                </td>
                                <td>{item?.applicant_name}</td>
                                <td>{item?.dob}</td>
                                <td>{item?.occupation}</td>
                                <td>{item?.created_date}</td>
                                <td>
                                  <div className="btn-container">
                                    <button
                                      className="btn btn-sm edit"
                                      style={{
                                        backgroundColor: "#055837",
                                        color: "#ffcc01",
                                        marginRight: "8px",
                                        transition:
                                          "background-color 0.2s, color 0.2s",
                                      }}
                                      onClick={handleEdit.bind(
                                        this,
                                        item?.id,
                                        item?.applicant_name
                                      )}
                                    >
                                      <i className="bi bi-pencil-square"></i>
                                    </button>
                                    <button
                                      className="btn btn-sm btn-danger text-white"
                                      style={{ marginLeft: "8px" }}
                                      onClick={handleDelete.bind(
                                        this,
                                        item?.id,
                                        `${item?.applicant_name}`
                                      )}
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-3 thick-hr" />

                {/* <div className="pagination-container">
                  <div className="dataTables_info">
                    Showing {ExtraData?.currentPage} to{" "}
                    {ExtraData?.itemsPerPage} of {ExtraData?.totalRows} entries
                  </div>

                  <div className="pagination-buttons">
                    <button
                      className={`paginate_button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                      style={
                        currentPage === 1
                          ? {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    >
                      Previous
                    </button>

                    <button className="paginate_button">{currentPage}</button>

                    <button
                      className={`paginate_button ${
                        ExtraData?.next_disabled === true ? "disabled" : ""
                      }`}
                      onClick={handleNext}
                      disabled={ExtraData?.next_disabled === true}
                      style={
                        ExtraData?.next_disabled === true
                          ? {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                            }
                          : {}
                      }
                    >
                      Next
                    </button>
                  </div>
                </div> */}




<div className="pagination-container">
                  <div className="dataTables_info">
                    Showing {ExtraData?.currentPage} to{" "}
                    {ExtraData?.itemsPerPage} of {ExtraData?.totalRows} entries
                  </div>

                  {/* <span
                    style={{
                      background:
                        "linear-gradient(to bottom right, #fff9c4, #fff3e0)",
                      color: "#055837",
                      padding: "8px 12px",
                      borderRadius: "5px",
                      // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      // fontWeight: 'bold',
                      // fontSize: '16px',
                      display: "inline-block",
                      cursor: "default",
                    }}
                  >
                    Active Page : {currentPage}
                  </span> 
                  
                  */}

                  {/* 
                  

<div className="pagination-buttons">

<button
  className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
  onClick={handleFirst}
  disabled={currentPage === 1}
  style={currentPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
>
  First
</button>


    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
        onClick={handlePrev}
        disabled={startPage === 1}
        style={startPage === 1 ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Previous
      </button>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
        {Array.from({ length: visiblePages }, (_, index) => {
          const pageNumber = startPage + index;
          if (pageNumber > totalPages) return null; // Do not render if out of bounds

          const isActive = currentPage === pageNumber;

          return (
            <button
              key={pageNumber}
              className={`paginate_button ${isActive ? "active" : ""}`}
              style={{
                display: 'inline-block',
                margin: '0 4px',
                backgroundColor: isActive ? "black" : "transparent",
                color: isActive ? "white" : "black",
              }}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className={`paginate_button ${(startPage + visiblePages - 1 >= totalPages) ? "disabled" : ""}`}
        onClick={handleNext}
        disabled={startPage + visiblePages - 1 >= totalPages}
        style={startPage + visiblePages - 1 >= totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
      >
        Next
      </button>
    </div>

    <button
      className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
      onClick={handleLast}
      disabled={currentPage === totalPages}
      style={currentPage === totalPages ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
    >
      Last
    </button>
  </div> */}

                  <div className="pagination-buttons">
                    <button
                      className={`paginate_button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handleFirst}
                      disabled={currentPage === 1}
                    >
                      First
                    </button>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        className={`paginate_button ${
                          startPage === 1 ? "disabled" : ""
                        }`}
                        onClick={handlePrev}
                        disabled={startPage === 1}
                      >
                        Previous
                      </button>

                      <div
                        style={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          flex: 1,
                        }}
                      >
                        {Array.from({ length: visiblePages }, (_, index) => {
                          const pageNumber = startPage + index;
                          if (pageNumber > totalPages) return null;

                          const isActive = currentPage === pageNumber;

                          return (
                            <button
                              key={pageNumber}
                              className={`paginate_button ${
                                isActive ? "active" : ""
                              }`}
                              style={{
                                display: "inline-block",
                                margin: "0 4px",
                                backgroundColor: isActive
                                  ? "black"
                                  : "transparent",
                                color: isActive ? "white" : "black",
                              }}
                              onClick={() => handlePageClick(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          );
                        })}
                      </div>

                      <button
                        className={`paginate_button ${
                          startPage + visiblePages - 1 >= totalPages
                            ? "disabled"
                            : ""
                        }`}
                        onClick={handleNext}
                        disabled={startPage + visiblePages - 1 >= totalPages}
                      >
                        Next
                      </button>
                    </div>

                    <button
                      className={`paginate_button ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                      onClick={handleLast}
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </button>
                  </div>
                </div>


              </div>
            </div>
          </Card.Body>

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Dashboard page?"
            />
          )}

          {deleteButtonClicked && (
            <ConfirmationModal
              show={showConfirmationDeleteModal}
              onHide={handleConfirmationDeleteNo}
              onConfirm={handleConfirmationDeleteYes}
              title="Confirm Delete"
              message={`Are you sure you want to delete <strong>${deletingPersonName}</strong> Incomplete Application?`}
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Logout"
              message="Are you sure you want to log out?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default IncompleteApplications;
