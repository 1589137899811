import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


export default function PrivacyModal({show, onHide}) {
    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title className="text-center" style={{ fontSize: "18px", fontFamily: "Arial, sans-serif", color: "#055837" }}>
              <b>PRIVACY COLLECTION NOTICE</b>
              </Modal.Title>
            </Modal.Header>
          <Modal.Body className="text-left" style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}>
            <p>
                This Privacy Collection Notice describes how Global Skills Development Pty Ltd (ACN 622 891 990) (<b>we, us or our</b>) collects and handles your personal information when you apply for a skills assessment with us. We collect personal information from you and from third parties (such as your referees, your previous and current employers, Registered Training Organisations and where applicable, your migration agents) so that we can verify and assess your application for related purposes set out in our Privacy Policy, available on our website (or on request). <br/>
                We may disclose this personal information to third parties, including our personnel, related entities, any third parties engaged by us and acting on our behalf and as otherwise set out in our Privacy Policy. <br/>
                We may store personal information overseas. Where we disclose your personal information to third parties, those third parties may also store, transfer or access personal information outside of Australia.<br/>
                If you do not provide your personal information to us, it may affect our ability to do business with you.<br/>
                Please see our Privacy Policy for more information about how we collect, store, use and disclose your personal information, including details about overseas disclosure, access, correction, how you can make a privacy-related complaint and our complaint-handling process.<br/>
                If you have questions about our privacy practices, please contact us by email at: <a href='mailto:skills@aqato.com.au'>skills@aqato.com.au</a>. By providing your personal information to us, you agree to the collection, use, storage and disclosure of that information as described in this privacy collection notice.<br/>
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="light"
              onClick={onHide}
              className="w-50"
              style={{
                maxWidth: "130px",
                backgroundColor: "#055837",
                color: "#ffcc01",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              Ok
            </Button>
            {/* <Button
              variant="light"
              onClick={onConfirm}
              className="w-50"
              style={{
                maxWidth: "130px",
                backgroundColor: "#055837",
                color: "#ffcc01",
                transition: "transform 0.2s",
              }}
              onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
              onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            >
              Yes
            </Button> */}
          </Modal.Footer>
        </Modal>
      );
}
