import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { Button, Col, Row, Spinner } from "react-bootstrap";

import ConfirmationModal from "../../Confirmation Modal/ConfirmationModal";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";

import { useLocation } from "react-router-dom";
import Heading from "../../Heading/Heading";
import NavigationButtons from "../../Navigation Buttons/Navigation Buttons";


import AssessmentDocuments from "../stage_2/Documentary Evidence Upload Docs/Assessment Documents/Assessment Documents";
import { FinalSubmission_Of_stage_2_R_API } from "../../../api";
import Loader from "../../Loader/Loader";
import Additional_Supporting_Evidence from "./Additional_Supporting_Evidence/Additional_Supporting_Evidence";
import NotePopup from "../stage_3/On Shore/Note Popup/Note Popup";

const Stage_2_R_Submit_Page = ({ onLogout }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [loading, setLoading] = useState(false);

  const [notePopup, setNotePopup] = useState(true);

  const [termsChecked, setTermsChecked] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleBack = () => {
    navigate("/user/view_application", {
      state: { pointerData },
    });
    // toast.success("Navigated to documentary evidence form page.");
  };

  const [showConfirmationSubmitModal, setShowConfirmationSubmitModal] =
    useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const handleConfirmationSubmitNo = () => {
    setShowConfirmationSubmitModal(false);
  };

  const handleNext = async (e) => {
    e.preventDefault();

    if (!termsChecked) {
      toast.error("Agree to the terms.");
      document.getElementById("termsAndConditions").focus();
      return;
    }

    setSubmitButtonClicked(true);
    setShowConfirmationSubmitModal(true);
  };

  const handleConfirmationSubmitYes = async (e) => {
    e.preventDefault();

    setSubmitButtonClicked(false);
    setShowConfirmationSubmitModal(false);

    if (!termsChecked) {
      toast.error("Agree to the terms.");
      document.getElementById("termsAndConditions").focus();
      return;
    }

    try {
      setLoading(true);
      const response = await FinalSubmission_Of_stage_2_R_API(
        pointerData?.pointer_id
      );
  

      if (response?.data?.response?.response === true) {
        toast.success("Stage 2 (R) submitted successfully.");
        navigate("/user/view_application", { state: { pointerData } });
        setLoading(false);
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Please Upload All The Required Documents"
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving stage 2 (R):", error);
      toast.error("Failed to save stage 2 (R)");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = () => {
    navigate("/user/view_application", {
      state: { pointerData },
    });
    setShowConfirmationModal(false);
    toast.warning(
      "Employment Details saved successfully. Now please Submit Stage 2 (R)."
    );
  };

  const fakeSubmit = (e) => {
    e.preventDefault();
  };

  const handleSaveAndExit = () => {
    setShowConfirmationModal(true);
  };

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const [
    showConfirmationBackTodashboardModal,
    setShowConfirmationBackTodashboardModal,
  ] = useState(false);
  const [backtodashboardButtonClicked, setBackToDashboardButtonClicked] =
    useState(false);

  const handleConfirmationBackToDashboardNo = () => {
    setShowConfirmationBackTodashboardModal(false);
  };

  const handleConfirmationBackToDashboardYes = () => {
    // navigate("/user/dashboard");

    navigate("/user/view_application", {
      state: { pointerData },
    });
    setShowConfirmationBackTodashboardModal(false);
    toast.success("Navigated to View Application.");
  };

  const handleViewApplication = () => {

    navigate("/user/view_application", {
      state: { pointerData },
    });
    toast.success("Navigated to View Application.");

    // setBackToDashboardButtonClicked(true);
    // setShowConfirmationBackTodashboardModal(true);
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleViewApplication}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to View Application
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Stage 2 (R) - Documentary Evidence" />
      </Row>

      <div className="documentary-upload-docs-container-contact-details">
        <Card className="shadow documentary-upload-docs-card-contact-details">
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-1 mt-1">
                  Application No. :{" "}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                    {/* (T.B.A.) */}
                  </b>
                </div>
              </div>
            </div>

            <Card.Body>
              <Form onSubmit={fakeSubmit}>
                <Row>
                  <Col md={6}>



                  <Additional_Supporting_Evidence 
                  heading_text = "Additional Supporting Evidence"
                  required_document_id = "60"
                  setLoading = {setLoading}
                  />


                    {/* <EmploymentDocuments /> */}
                  </Col>

                  <Col md={6}>
                    {/* <AssessmentDocuments /> */}

                  <Additional_Supporting_Evidence 
                  heading_text = "Assessment Documents"
                  required_document_id = "59"
                  setLoading = {setLoading}
                  />
                  </Col>
                </Row>

                <hr className="mb-2 mt-1 thick-hr" />

                <div
                  className="form-group form-check mb-1"
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="termsAndConditions"
                    name="termsAndConditions"
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                    style={{
                      transform: "scale(1.3)",
                      border: "1px solid #055837",
                      color: "#055837",
                      backgroundColor: termsChecked ? "#055837" : "",
                        cursor:"pointer"
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditions"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    
                    }}
                  >
                    I understand & agree that once I submit the documents, I
                    will not be able to remove or change these documents.
                  </label>
                </div>

                {/* 
                {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

                {loading && <Loader />}

                {/* Back and Save & Exit and Next Buttons */}

                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Back Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleBack}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                    >
                      Back
                    </button>

                    {/* Save & Exit Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveAndExit}
                      style={{
                        backgroundColor: "#055837",
                        color: "#ffcc01",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                    >
                      Save & Exit
                    </button>

                    {/* Next Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleNext}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the view application."
          />

          {backtodashboardButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackTodashboardModal}
              onHide={handleConfirmationBackToDashboardNo}
              onConfirm={handleConfirmationBackToDashboardYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the View Application page?"
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Logout"
              message="Are you sure you want to log out?"
            />
          )}

          {submitButtonClicked && (
            <ConfirmationModal
              show={showConfirmationSubmitModal}
              onHide={handleConfirmationSubmitNo}
              onConfirm={handleConfirmationSubmitYes}
              title="Confirm Stage 2 (R) Submission"
              message="Are you sure you want to submit the application? You will not be able to remove or change these documents after submission?"
              privacy_popup="yes"
            />
          )}
          {/* <NotePopup
                show={notePopup}
                onHide={() => {
                  setNotePopup(false);
                }}
                title="Important Notice!"
                matter={"For Reassessment you can provide additional supporting evidence for any particular employer by choosing the employer "}
              /> */}
        </Card>
      </div>
    </>
  );
};

export default Stage_2_R_Submit_Page;
