
import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import './Employment Verification Pending.css'
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button } from "react-bootstrap";
import Heading from "../Heading/Heading";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import {
  getEmployment_Verification_Pending_API,
  deleteIncomplete_Application_API,
} from "../../api";
import Loader from "../Loader/Loader";

const EmploymentVerificationPending = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1); // Reset to the first page whenever the items per page change
  };

  const [deletingPersonName, setDeletingPersonName] = useState("");
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);

  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);



  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);





















  
  const handleViewEmploment = (pointer_id,applicant_name) => {
    setPointerID(pointer_id);
    localStorage.setItem('active_candidate_name', applicant_name);

    navigate("/user/employment_verification_pending_details", {
      state: { pointer_id },
    });

  };




















  const [PointerID, setPointerID] = useState(null);

  const [showConfirmationDeleteModal, setShowConfirmationDeleteModal] =
    useState(false);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const visiblePages = 5;
  const totalPages = Math.ceil(originalData.length / dataPerPageButton);
  // const startPage = 1;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));


  const getEmploymentVerficationFunction = async () => {
    try {
      setLoading(true);

      const response = await getEmployment_Verification_Pending_API();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const employmentVerificationData = response?.data?.response?.data;
        setOriginalData(employmentVerificationData);
        setCurrentPageData(
          employmentVerificationData.slice(0, parseInt(dataPerPageButton))
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getEmploymentVerficationFunction();
  }, []);

  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Dashboard.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/dashboard");


  };

  const handleConfirmationDeleteNo = () => {
    setShowConfirmationDeleteModal(false);
  };

  const handleDelete = (pointer_id, fullName) => {
    setDeleteButtonClicked(true);
    setShowConfirmationDeleteModal(true);
    setPointerID(pointer_id);
    setDeletingPersonName(fullName);
  };

  const handleConfirmationDeleteYes = async () => {
    try {
      setLoading(true);

      const response = await deleteIncomplete_Application_API(PointerID);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        setShowConfirmationDeleteModal(false);
        getEmploymentVerficationFunction();
        toast.success("Application Deleted successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to delete Incomplete Application"
        );
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to delete Incomplete Application");
    }
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };
  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };



  const handleFirst = () => {
    setCurrentPage(1);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLast = () => {
    setCurrentPage(Math.ceil(originalData.length / dataPerPageButton));
  };

  useEffect(() => {
    // Calculate index of the last item on the current page
    const indexOfLastItem = currentPage * dataPerPageButton;
    // Calculate index of the first item on the current page
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    // Slice the original data array to get current page's data
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    // Update current page data state
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  // useEffect(() => {
  //   if (searchQuery === "") {
  //     setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //     setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
  //   }
  // }, [searchQuery, dataPerPageButton, originalData]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * dataPerPageButton;
    const endIndex = currentPage * dataPerPageButton;

    // Filter the original data based on the search query
    let filteredData = originalData;
    if (searchQuery !== "") {
      filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    // Slice the filtered data based on the current page
    const currentPageData = filteredData.slice(startIndex, endIndex);

    setCurrentPageData(currentPageData);
  }, [searchQuery, dataPerPageButton, originalData, currentPage]);
  

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to Dashboard
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Employment Verification" />
      </Row>

      {/* {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}


{loading && (
 <Loader />
      )}


      <div className="incomplete-info">
        <Card className="shadow forgot-card-identification">
          <Card.Body>
            <div className="account-details">
              <div id="table_wrapper" className="dataTables_wrapper no-footer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="dataTables_length">
                    <label>
                      <select
                        value={dataPerPageButton}
                        onChange={handleDataPerPageChange}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>

                  <div id="table_filter" className="dataTables_filter">
                    <label>
                      <i className="fa fa-search"></i>
                      <input
                        type="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                </div>




                {originalData.length === 0 && !loading &&(
  <div className="no-data-message">
    <p className="oops-message">Oops! No data to display.</p>
  </div>
)}

{showNoDataMessage && (
  <div className="no-data-message">
    <p className="oops-message">Oops! No relevant results found.</p>
  </div>
)}




                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table table-striped table-hover dataTable no-footer">
                          <thead>
                            <tr>
                              <th scope="col" class="col-1">
                                PRN
                              </th>
                              <th scope="col" class="col-2">
                                Application No.
                              </th>
                              <th scope="col" class="col-3">
                          Applicant Name
                              </th>
                              <th scope="col" class="col-2">
                            DOB
                              </th>
                              <th scope="col" class="col-3">
                            Occupation
                              </th>
                              <th scope="col" class="col-1">
                                Action
                              </th>
                            </tr>
                          </thead>




                      


                          <tbody>



                            {currentPageData.map((item, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td>
                                  <strong>{item?.prn}</strong>
                                </td>
                                <td>
                                  {item?.unique_code}
                                </td>
                                <td>
                                {item?.full_name}
                                </td>
                                <td> {item?.dob}</td>
                                <td>
                                  {item?.occupation_name}
                                </td>
                                <td>
                                  <div className="btn-container">
                                    <button
                                      className="btn btn-sm edit"
                                      style={{
                                        backgroundColor: "#055837",
                                        color: "#ffcc01",
                                        marginRight: "8px",
                                        transition:
                                          "background-color 0.2s, color 0.2s",
                                      }}
                                      onClick={handleViewEmploment.bind(
                                        this,
                                        item?.id,
                                        item?.full_name
                                      )}
                                    >
                                      <i className="bi bi-eye-fill eye-open-click"></i>
                                    </button>
                                    {/* <button
                                      className="btn btn-sm btn-danger text-white"
                                      style={{ marginLeft: "8px" }}
                                      onClick={handleDelete.bind(
                                        this,
                                        item?.pointer_id,
                                        `${item?.first_or_given_name} ${item?.surname_family_name}`
                                      )}
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-3 thick-hr" />

                <div className="pagination-container">
                 <div className="dataTables_info">
  Showing{" "}
  {originalData.length > 0
    ? (
        currentPage === 1
          ? 1
          : (currentPage - 1) * dataPerPageButton + 1
      )
    : 0}{" "}
  to{" "}
  {originalData.length > 0
    ? Math.min(
        currentPage * dataPerPageButton,
        originalData.length
      )
    : 0}{" "}
  of {originalData.length} entries
</div>


                      {/* <span 
    style={{
      background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
      color: '#055837', 
      padding: '8px 12px',
      borderRadius: '5px',
      display: 'inline-block',
      cursor: "default"
    }}
  >
    Active Page : {currentPage}
  </span> */}

                      <div className="pagination-buttons">
                        <button
                          className={`paginate_button ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={handleFirst}
                          disabled={currentPage === 1}
                        >
                          First
                        </button>

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button
                            className={`paginate_button ${
                              startPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrev}
                            disabled={startPage === 1}
                          >
                            Previous
                          </button>

                          <div
                            style={{
                              overflowX: "auto",
                              whiteSpace: "nowrap",
                              flex: 1,
                            }}
                          >
                            {Array.from(
                              { length: visiblePages },
                              (_, index) => {
                                const pageNumber = startPage + index;
                                if (pageNumber > totalPages) return null;

                                const isActive = currentPage === pageNumber;

                                return (
                                  <button
                                    key={pageNumber}
                                    className={`paginate_button ${
                                      isActive ? "active" : ""
                                    }`}
                                    style={{
                                      display: "inline-block",
                                      margin: "0 4px",
                                      backgroundColor: isActive
                                        ? "black"
                                        : "transparent",
                                      color: isActive ? "white" : "black",
                                    }}
                                    onClick={() => handlePageClick(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                );
                              }
                            )}
                          </div>

                          <button
                            className={`paginate_button ${
                              startPage + visiblePages - 1 >= totalPages
                                ? "disabled"
                                : ""
                            }`}
                            onClick={handleNext}
                            disabled={
                              startPage + visiblePages - 1 >= totalPages
                            }
                          >
                            Next
                          </button>
                        </div>

                        <button
                          className={`paginate_button ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={handleLast}
                          disabled={currentPage === totalPages}
                        >
                          Last
                        </button>
                      </div>
                    </div>
              </div>
            </div>
          </Card.Body>

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Dashboard page?"
            />
          )}

          {deleteButtonClicked && (
            <ConfirmationModal
              show={showConfirmationDeleteModal}
              onHide={handleConfirmationDeleteNo}
              onConfirm={handleConfirmationDeleteYes}
              title="Confirm Delete"
              message={`Are you sure you want to delete <strong>${deletingPersonName}</strong> Incomplete Application?`}
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Logout"
              message="Are you sure you want to log out?"
            />
          )}


        </Card>
      </div>
    </>
  );
};

export default EmploymentVerificationPending;
