import React, { useState, useEffect } from "react";
import "./View Application.css";
import { Button, Col, Row, Card, Spinner, Table, Form, Modal } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import Heading from "./../../Heading/Heading";
import NavigationButtons from "./../../Navigation Buttons/Navigation Buttons";
import ConfirmationModal from "../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
// import backgroundImage from "./bg_of_dashboard1.png";
import backgroundImage from "./loader.gif";

import { BsDownload } from "react-icons/bs";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import PopupImage from "./new_popup_.png";
import EmploymentVerificationTableModal from "./Employment Verification Table Modal/Employment Verification Table Modal";
import { get_ViewApplications_API, call_reinstate_stage_2_R_api } from "../../../api";
import AdditionalInformation from "./Additional Information/Additional Information";
import Loader from "../../Loader/Loader";
import Flag_popup from "./Flag_popup";
import Custom_Ok_Popup from "../../Common Modules/Custom_Ok_Popup";

const ViewApplication = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;
  const [showOptions, setShowOptions] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };



  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const [PopUpModalClicked, setPopUpModalClicked] = useState(false);
  const [showPopUpModalClicked, setShowPopUpModalClicked] = useState(false);

  const handlePopupModalYes = () => {
    setShowPopUpModalClicked(true);
    setPopUpModalClicked(true);
  };

  const handlePopupModalNo = () => {
    setShowPopUpModalClicked(false);
    setPopUpModalClicked(false);
  };

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);

  const [showApplyforReassessment, setShowApplyforReassessment] = useState(false);

  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };

  const handleConfirmationBackYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Submitted Applicantions.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/submitted_applications", {
      state: { pointerData },
    });
    toast.success("Navigated to Submitted Applicantions.");
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  // const navigateToStage = () => {
    // navigate("/user/stage_2/documentary_evidence", {
    //   state: { pointerData: pointerData },
    // });

    // navigate("/user/stage_3/offshore_technical_interview", {
    //   state: { pointerData: pointerData },
    // });

    //  navigate("/user/stage_3/onshore_technical_interview", {
    //   state: { pointerData: pointerData },
    //   });

        // navigate("/user/stage_3_R/offshore_technical_interview", {
    //   state: { pointerData: pointerData },
    // });

    //  navigate("/user/stage_3_R/onshore_technical_interview", {
    //   state: { pointerData: pointerData },
    //   });

    // navigate("/user/stage_4/practical_assessment", {
    //   state: { pointerData: pointerData },
    //   });
  // };



  const [ApplicationDetails, setApplicationDetails] = useState({});
  

  const call_reinstate_stage_2_R = async () => {
    
    // return;
    // setShowApplyforReassessment(false);
    const pointer_id = pointerData?.pointer_id;
    try {
      setLoading(true);
      const response = await call_reinstate_stage_2_R_api(pointer_id);
  
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data
      ) {
        if(response?.data?.response?.response === true){
          setShowApplyforReassessment(false);
          get_ViewApplication_Function(pointer_id);
        }
        
        setLoading(false);

      } else {
        setLoading(false);
        console.error(
          "Error fetching employment details:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching employment details:", error);
    }
  };

  const get_ViewApplication_Function = async (pointer_id) => {
    try {
      const response = await get_ViewApplications_API(pointer_id);
      
  
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data
      ) {
        const Details = response?.data?.response?.data;


        setApplicationDetails(Details);



        if (Details?.navigate_to_final_page === true) {
          navigate("/user/final_page", {
            state: { pointerData },
          });
        }

        


      } else {
        
        console.error(
          "Error fetching employment details:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      
      console.error("Error fetching employment details:", error);
    }
  };
  useEffect(() => {
    if (pointerData?.pointer_id) {
      get_ViewApplication_Function(pointerData.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  useEffect(() => {
    if (pointerData?.pointer_id) {
      const intervalId = setInterval(() => {
        get_ViewApplication_Function(pointerData.pointer_id);
      }, 8000);
  
      get_ViewApplication_Function(pointerData.pointer_id);
  
      return () => clearInterval(intervalId);
    }
  }, [pointerData?.pointer_id]);
  




    

  const calculateCardHeight = () => {
    const baseHeight = "auto";

    if (showOptions) {
      return `${baseHeight}`;
    } else {
      return "auto";
    }
  };


  // const calculateCardHeight = () => {
  //   let baseHeight = "auto";
  
  //   if (showOptions) {
  //     const computedHeight = window.innerHeight + (7 * window.innerHeight / 100);
  //     baseHeight = `${computedHeight}px`;
  //   }
  
  //   return baseHeight;
  // };
  

  

  const cardStyle = { height: calculateCardHeight() };


  const [ActiveStageByAPI, setActiveStageByAPI] = useState("");
  const [ActiveStageByClick, setActiveStageByClick] = useState("");

  const handleTabClick = (stage) => {
    setActiveStageByClick(stage);
  };



  useEffect(() => {
    setActiveStageByAPI(ApplicationDetails?.is_active);
    setActiveStageByClick(ApplicationDetails?.is_active);

  }, [ApplicationDetails?.is_active]);





  const isButtonDisabled = (buttonId) => {
    const stages = [
      "stage_1-tab",
      "stage_2-tab",
      "stage_2_R-tab",
      "stage_3-tab",
      "stage_3_R-tab",
      "stage_4-tab",
    ];
    const ActiveStageByAPIIndex = stages.indexOf(`${ActiveStageByAPI}-tab`);
    const currentButtonIndex = stages.indexOf(buttonId);
  
    return currentButtonIndex > ActiveStageByAPIIndex;
  };

  const stageDetails = [
    { id: "stage_1", label: "Stage 1 - Self Assessment" },
    { id: "stage_2", label: "Stage 2 - Documentary Evidence" },
    { id: "stage_2_R", label: "Stage 2 (R) - Documentary Evidence" },
    { id: "stage_3", label: "Stage 3 - Technical Interview" },
    { id: "stage_3_R", label: "Stage 3 - Technical Interview (Reassessment)" },
    { id: "stage_4", label: "Stage 4 - Practical Assessment" },
  ];
  
  const filteredStages = stageDetails.filter(
    (stage) => ApplicationDetails?.over_stage_status?.[stage.id] === true
  );

  console.log("filteredStages", filteredStages);








  const getStatusValue = (stage) => ApplicationDetails?.[stage]?.status;
  const StatusValueByAPI = getStatusValue(ActiveStageByClick);





  const getSubmissionDateValue = (stage) => ApplicationDetails?.[stage]?.submitted_date;
  const SubmissionDateByAPI = getSubmissionDateValue(ActiveStageByClick);



  const getApprovalDateValue = (stage) => ApplicationDetails?.[stage]?.approved_date;
  const ApprovalDateByAPI = getApprovalDateValue(ActiveStageByClick);




  
  const getDeclinedDateValue = (stage) => ApplicationDetails?.[stage]?.declined_date;
  const DeclinedDateByAPI = getDeclinedDateValue(ActiveStageByClick);




    
  const getOmmitedDateValue = (stage) => ApplicationDetails?.[stage]?.omitted_date;
  const OmmitedDateByAPI = getOmmitedDateValue(ActiveStageByClick);





  
    
  const getWithdrawDateValue = (stage) => ApplicationDetails?.[stage]?.withdraw_date;
  const WithdrawDateByAPI = getWithdrawDateValue(ActiveStageByClick);



    
  const getClosedDateValue = (stage) => ApplicationDetails?.[stage]?.closed_date;
  const ClosedDateByAPI = getClosedDateValue(ActiveStageByClick);




  const getNoShowDateByAPI = (stage) => ApplicationDetails?.[stage]?.no_show_date;
  const NoShowDateByAPI = getNoShowDateByAPI(ActiveStageByClick);

  

  





  const getWithdraw_nrDateValue = (stage) => ApplicationDetails?.[stage]?.withdraw_date_nr;
  const Withdraw_nrDateByAPI = getWithdraw_nrDateValue(ActiveStageByClick);






  


  
  const getExpiryModule = (stage) => ApplicationDetails?.[stage]?.expiry_module;
  const ExpiryModuleByAPI = getExpiryModule(ActiveStageByClick);


  




  



  const getExpiryDateLabel = (stage) => ApplicationDetails?.[stage]?.label_date_expire;
  const ExpiryDateLabelByAPI = getExpiryDateLabel(ActiveStageByClick);


  
  const getExpiryDateValue = (stage) => ApplicationDetails?.[stage]?.expiry_date;
  const ExpiryDateByAPI = getExpiryDateValue(ActiveStageByClick);



  const getExpiryNoOfDaysValue = (stage) => ApplicationDetails?.[stage]?.expiry_no_of_days;
  const ExpiryNoOfDaysByAPI = getExpiryNoOfDaysValue(ActiveStageByClick);








  const getBookingDetails = (stage) => ApplicationDetails?.[stage]?.Interview_Schedule || [];
  const BookingDetailsByAPI = getBookingDetails(ActiveStageByClick);






  const getQualificationVerificationValue = ApplicationDetails?.stage_1?.qualification_verification;
  const getEmailVerificationValue = ApplicationDetails?.stage_2?.email_verification;




  
  const getNoteValue = (stage) => ApplicationDetails?.[stage]?.note;
  const NoteByAPI = getNoteValue(ActiveStageByClick);



  const getButtonsValue = (stage) => ApplicationDetails?.[stage]?.btns || [];
  const ButtonsByAPI = getButtonsValue(ActiveStageByClick);
  
  const renderButtons = (buttons = []) => {
    return buttons.map((button, index) => {
      const colSize = button.size === 'half' ? 6 : 12; 
      return (
        <Col sm={colSize} className="p-3" key={index}>
          <a 
            href={button.btn_link} 
            className="btn btn_green_yellow w-100" 
            download 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {button.btn_label} <i className="bi bi-download"></i>
          </a>
        </Col>
      );
    });
  };
  
  
  

  const getAdditionalInformationValue = (stage) => ApplicationDetails?.[stage]?.additonal_request || [] || {};
  const AdditionalInformationByAPI = getAdditionalInformationValue(ActiveStageByClick);











  const getSubmittedDocsList = (stage) => ApplicationDetails?.[stage]?.docs || {};
  const SubmittedDocsListByAPI = getSubmittedDocsList(ActiveStageByClick);
  
  const renderDocumentRows = (docs, category, stageChecker) => (
    <>
      {(stageChecker === "stage_2" || stageChecker === "stage_2_R") && (
        <tr className="">
          <td colSpan={3} style={{  background: "#f0f0f0" }} >
          {/* <span>{category}</span> */}

          <span>
  <i className="fas fa-city" style={{ marginRight: "7px" }}></i>
  {category}
</span>

          </td>
        </tr>
      )}
      {Array.isArray(docs) ? (
        docs.map((doc, index) => (
          <tr key={index}>
            <td style={{ color: "#055837"}}>
              {index + 1}
            </td>

            <td style={{ display: "flex", alignItems: "center" }}>
  <a
    href={doc.full_link}
    target={(doc.full_link === "javascript:void(0)") ? "" : "_blank"}
    rel="noopener noreferrer"
    style={{ color: "#055837", textDecoration: "none" }}
    onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
    onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
    className={(doc.full_link === "javascript:void(0)") ? "opacity-low" : ""}
  >
    {doc?.name}
  </a>
  <span style={{ color: "black", marginLeft: "4px" }}>
    {doc?.is_additional === "yes" && "(Additional Info)"}
  </span>
</td>


          </tr>
        ))
      ) : (
        Object.keys(docs).map((subCategory, idx) => (
          <>
       

{stageChecker === "stage_2" && (
        <tr className="">
          <td colSpan={3} style={{  background: "#f0f0f0" }} >
          <span>
  <i className="fas fa-city" style={{ marginRight: "7px" }}></i>
  
  {subCategory}</span>
          </td>
        </tr>
      )}

            {docs[subCategory].map((doc, index) => (
              <tr key={`${subCategory}-${index}`}>
                <td style={{ color: "#055837", marginLeft: "10px" }}>
                  {index + 1}
                </td>
                <td style={{ display: "flex", alignItems: "center" }}>
  <a
    href={doc.full_link}
    target={(doc.full_link === "javascript:void(0)") ? "" : "_blank"}
    rel="noopener noreferrer"
    style={{ color: "#055837", textDecoration: "none" }}
    onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
    onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
    className={(doc.full_link === "javascript:void(0)") ? "opacity-low" : ""}
  >
    {doc?.name}
  </a>
  <span style={{ color: "black", marginLeft: "4px" }}>
    {doc?.is_additional === "yes" && "(Additional Info)"}
  </span>
</td>

              </tr>
            ))}
          </>
        ))
      )}
    </>
  );




  const getNextStageNavigation = (stage) => ApplicationDetails?.[stage]?.next_btn || {} || [];

  const NextStageNavigationByAPI = getNextStageNavigation(ActiveStageByClick);
  
  const isNextStageAvailable =
    typeof NextStageNavigationByAPI === 'object' &&
    NextStageNavigationByAPI !== null &&
    'label' in NextStageNavigationByAPI &&
    'url' in NextStageNavigationByAPI;
  

  const navigateToStage = (url) => {
    navigate(url, { state: { pointerData: pointerData } });
  };












  return (
    <> 
    { loading && <Loader /> }
      <Row style={{
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        width: "100%",
        zIndex: "99",
        backgroundColor: "white"
      }}>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to Submitted Applications
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Application Details" />
      </Row>

      <div className="forgot-container-viewApplication">
        <Card className="shadow forgot-card-viewApplication" style={cardStyle}>
          <Card.Body>




            {/* {loading && (
              <div className="d-flex justify-content-center mt-3">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )} */}



            <Row>
              <Col md={8}>
                <div className="table-container">
                  <Table className="custom-tableData">
                    <tbody>
                      <tr>
                        <td className="bold-green">Portal Reference Number</td>
                        <td className="bold-green">
                          {pointerData?.portal_refrance_no}
                        </td>
                      </tr>
                      <tr>
                        <td className="bold-green">Application No.</td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.Application_Details?.unique_id}
                        </td>
                      </tr>

                      <tr>
                        <td className="bold-green">Applicant's Name</td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.Application_Details?.name}
                        </td>
                      </tr>

                      <tr>
                        <td className="bold-green">Occupation</td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.Application_Details?.occupation}
                        </td>
                      </tr>

                      <tr>
                        <td className="bold-green">Pathway</td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.Application_Details?.pathway}
                        </td>
                      </tr>

                      <tr>
                        <td className="bold-green">Program</td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.Application_Details?.program}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>



              {/* <Col md={4}>
                <img
                  src={ApplicationDetails?.user_profile_link || backgroundImage}
                  alt="User Profile"
                  className="img-fluid"
                  style={{ height: "250px", border: "2px solid #ffcc01" }}
                />
              </Col> */}


<Col md={4} className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
  <div>
  <img
  src={ApplicationDetails?.user_profile_link || backgroundImage}
  alt="User Profile"
  className="img-fluid"
  style={{
    height: "250px",
    width: "250px",
    border: "2px solid #ffcc01",
    objectFit: "contain", // Ensures the image fits inside without being cropped or stretched
    borderRadius: "10px",
    backgroundColor: "#f0f0f0",
  }}
/>

  </div>
</Col>



            </Row>

            <div
  className="row nav nav-tabs Application_buttons_container"
  id="nav-tab"
  role="tablist"
>
{filteredStages.map((stage) => (
  <button
    key={stage.id}
    type="button"
    className={`application_button ${
      isButtonDisabled(`${stage.id}-tab`) ? "disabled" : "active"
    } col-sm btn arrow bg-green text-yellow      ${ActiveStageByClick === stage.id ? "active_by_click" : ""}  `}
    id={`${stage.id}-tab`}
    onClick={() => handleTabClick(stage.id)}
    aria-selected={ActiveStageByAPI === stage.id}
    disabled={isButtonDisabled(`${stage.id}-tab`)}
    style={{
      backgroundColor: ActiveStageByClick === stage.id ? "#055837" : "",
      color: ActiveStageByClick === stage.id ? "#ffcc01" : "",
      transition: "background-color, color",
    }}
  >
    {stage.label}
  </button>
))}

</div>


            <Row>
              <Col md={8}>
                <div className="table-container">
                  <Table className="custom-tableData">
                    <tbody>
                      <tr>
                        <td className="bold-green">Status</td>
                        <td className="bold-green">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {StatusValueByAPI}


                            {ActiveStageByClick === "stage_1" && getQualificationVerificationValue && getQualificationVerificationValue.length !== 0 && (
  <img
    id="PopupImage"
    onClick={handlePopupModalYes}
    className="PopupImageeeeeeeeee"
    src={PopupImage}
    style={{
      cursor: "pointer",
    }}
  />
)}


{ActiveStageByClick === "stage_2" && getEmailVerificationValue && getEmailVerificationValue.length !== 0 && (
  <img
    id="PopupImage"
    onClick={handlePopupModalYes}
    className="PopupImageeeeeeeeee"
    src={PopupImage}
    style={{
      cursor: "pointer",
    }}
  />
)}








                          </div>
                        </td>
                      </tr>


{SubmissionDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Submitted</td>
                        <td className="normal-greeenn-text">{SubmissionDateByAPI}</td>
                      </tr>
)}


                      {ApprovalDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Approved</td>
                        <td className="normal-greeenn-text">{ApprovalDateByAPI}</td>
                      </tr>

)}

{DeclinedDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Declined</td>
                        <td className="normal-greeenn-text">{DeclinedDateByAPI}</td>
                      </tr>

)}



{OmmitedDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Omited</td>
                        <td className="normal-greeenn-text">{OmmitedDateByAPI}</td>
                      </tr>

)}






{WithdrawDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Withdraw</td>
                        <td className="normal-greeenn-text">{WithdrawDateByAPI}</td>
                      </tr>

)}

{Withdraw_nrDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Withdrawn (NR)</td>
                        <td className="normal-greeenn-text">{Withdraw_nrDateByAPI}</td>
                      </tr>

)}


{ClosedDateByAPI && (
                      <tr>
                        <td className="bold-green">Date Closed</td>
                        <td className="normal-greeenn-text">{ClosedDateByAPI}</td>
                      </tr>

)}




{NoShowDateByAPI && (
                      <tr>
                        <td className="bold-green">Date No Show</td>
                        <td className="normal-greeenn-text">{NoShowDateByAPI}</td>
                      </tr>

)}











                  


{/* 

{ExpiryDateByAPI && (
                      <tr>
        
                        <td className="bold-green">{ExpiryDateLabelByAPI}</td>
                        
                        <td className="normal-greeenn-text">
                          {ExpiryDateByAPI}{" "}

                          {ExpiryNoOfDaysByAPI && (
  <span style={{ color: "red" }}>
    ({ExpiryNoOfDaysByAPI} Days Left)
  </span>
)}

                        </td>
                      </tr>

)} */}









{( ExpiryModuleByAPI && !Array.isArray(ExpiryModuleByAPI) && ExpiryModuleByAPI?.left_days !== "" && ExpiryModuleByAPI?.label !== "" )&& (
                      <tr>
              
                        <td className="bold-green">{ExpiryModuleByAPI?.label}</td>
                        
                        <td className="normal-greeenn-text">
                          {ExpiryModuleByAPI?.date}{" "}

                          {ExpiryModuleByAPI?.left_days !== "" && (
  <span style={{ color: "red" }}>
    ({ExpiryModuleByAPI?.left_days} Days Left)
  </span>
)}

                        </td>
                      </tr>

)}











                    </tbody>
                  </Table>


{NoteByAPI && (
                  <div class="mt-3 mb-1">
                    <label
                      htmlFor="inputName4"
                      className="form-label text-center w-100 py-2"
                      style={{
                        backgroundColor: "#ffc107",
                        color: "#055837",
                        fontSize: "17px",
                        borderRadius: "5px",
                        padding: "7px",
                      }}
                    >
                 {NoteByAPI}
                    </label>
                  </div>
)}


















    <Row>
        {renderButtons(ButtonsByAPI)}
      </Row>



      {isNextStageAvailable && (
  <Row>
    <Col sm={12} className="p-3">
      <button
        className="btn btn_yellow_green w-100"
        onClick={() => {
          const url = NextStageNavigationByAPI?.url;
          if(url === "open_yes_no_popup_stage_2_R"){
            console.log(url);
            setShowApplyforReassessment(true);
            // setBackButtonClicked(true);
          }
          else{
              navigateToStage(NextStageNavigationByAPI?.url)   
          }
          
        }
      }
      >
        {NextStageNavigationByAPI?.label} <i className="bi bi-arrow-right"></i>
      </button>
    </Col>
  </Row>
)}








                </div>
              </Col>





              <Col
                md={4}
                xs={12}
                className="dropdown-column"
            
              >
                <div className="dropdown-container">
                  <button
                    className="toggle-container"
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    <span className="toggle-heading">
                      Submitted Documents List
                    </span>
                    <div className="toggle-icons">
                      {showOptions ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                  </button>

                  <div
                    className={`options-container ${showOptions ? "show" : ""}`}
                  >
                 <table className="custom-table-for-submitted">
      <thead>
        <tr>
          <th>Sr.</th>
          <th>Document Name</th>
        </tr>
      </thead>
      {Array.isArray(SubmittedDocsListByAPI) ? (
        <tbody className="options-list">
          {renderDocumentRows(SubmittedDocsListByAPI, null, ActiveStageByClick)}
        </tbody>
      ) : (
        Object.keys(SubmittedDocsListByAPI).map((category, idx) => (
          <tbody key={idx} className="options-list">
            {renderDocumentRows(SubmittedDocsListByAPI[category], category, ActiveStageByClick)}
          </tbody>
        ))
      )}
    </table>
                  </div>
                </div>
              </Col>
            </Row>




















{BookingDetailsByAPI && BookingDetailsByAPI.length !== 0 && (



<div className="mt-3 mb-1">
<label
  htmlFor="inputName4"
  className="form-label text-center w-100 py-2"
  style={{
    backgroundColor: "#ffc107",
    color: "#055837",
    fontSize: "17px",
    borderRadius: "5px",
    padding: "7px",
  }}
>
  <div style={{ textAlign: "center", marginBottom: "5px", fontWeight: "bold" }}>
  {ActiveStageByClick === "stage_4" ? "Practical Booking Details" : "Interview Booking Details"}
  </div>
  <table style={{ width: "100%", color: "#055837" }}>
    <tbody>
    <tr>
  <td style={{ width: "15%", textAlign: "left", padding: "5px", fontWeight: "bold" }}>Day, Date / Time</td>
  <td style={{ width: "5%", textAlign: "center", padding: "5px" }}>:</td>
  <td style={{ width: "80%", textAlign: "left", padding: "5px" }}>
    <div> {BookingDetailsByAPI?.message_show_1}</div>
    <div> {BookingDetailsByAPI?.message_show_2}</div>
  </td>
</tr>


      <tr>
        <td style={{ width: "15%", textAlign: "left", padding: "5px", fontWeight: "bold" }}>Country</td>
        <td style={{ width: "5%", textAlign: "center", padding: "5px" }}>:</td>
        <td style={{ width: "80%", textAlign: "left", padding: "5px" }}>{BookingDetailsByAPI?.country}</td>
      </tr>


      <tr>
        <td style={{ width: "15%", textAlign: "left", padding: "5px", fontWeight: "bold" }}>Venue</td>
        <td style={{ width: "5%", textAlign: "center", padding: "5px" }}>:</td>
        <td style={{ width: "80%", textAlign: "left", padding: "5px" }}>{BookingDetailsByAPI?.venue}</td>
      </tr>
      <tr>
        <td style={{ width: "15%", textAlign: "left", padding: "5px", fontWeight: "bold" }}>Location</td>
        <td style={{ width: "5%", textAlign: "center", padding: "5px" }}>:</td>
        <td style={{ width: "80%", textAlign: "left", padding: "5px" }}>{BookingDetailsByAPI?.office_address}</td>
      </tr>
    </tbody>
  </table>
</label>
</div>




)}














 {AdditionalInformationByAPI && AdditionalInformationByAPI.length !== 0 && (
        <>
        {/* <Flag_popup /> */}
        <Custom_Ok_Popup />
        <AdditionalInformation get_ViewApplication_Function={get_ViewApplication_Function} AdditionalInformationByAPI={AdditionalInformationByAPI}   ActiveStageByClick={ActiveStageByClick} pointerIDDDDD={ pointerData?.pointer_id} />
        </>
          )} 







          </Card.Body>
        </Card>
      </div>



        

      



















      


      <ConfirmationModal
          show={showApplyforReassessment}
          onHide={() => {
            setShowApplyforReassessment(false);
          }}
          onConfirm={call_reinstate_stage_2_R}
          title="Stage 2 Reassessment"
          message="Are you sure you want to initiate the reassessment ?"
        />


      {backButtonClicked && (
        <ConfirmationModal
          show={showConfirmationBackModal}
          onHide={handleConfirmationBackNo}
          onConfirm={handleConfirmationBackYes}
          title="Confirm Navigation"
          message="Are you sure you want to go back to the Dashboard page?"
        />
      )}

      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Logout"
          message="Are you sure you want to log out?"
        />
      )}

      {PopUpModalClicked && (
        <EmploymentVerificationTableModal
          show={showPopUpModalClicked}
          onHide={handlePopupModalNo}
          pointerData={pointerData}
          getQualificationVerificationValue={getQualificationVerificationValue}
          getEmailVerificationValue={getEmailVerificationValue}
          ActiveStageByClick={ActiveStageByClick}
        />
      )}
    </>
  );
};

export default ViewApplication;
