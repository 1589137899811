import React, { useState, useEffect } from "react";
import { Card, Form, Spinner ,Table} from "react-bootstrap";
import "./View Employment Verification Pending.css";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import { Col, Row, Button } from "react-bootstrap";
import Heading from "../Heading/Heading";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import { getEmployment_Verification_Pending_details_API } from "../../api";
import { useLocation } from "react-router-dom";
import Loader from "../Loader/Loader";

const ViewEmploymentVerificationPending = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { pointer_id } = location.state;

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);

  const [Applicantdata, setApplicantdata] = useState(null);


  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const getEmploymentVerficationFunction = async () => {
    try {
      setLoading(true);

      const response = await getEmployment_Verification_Pending_details_API(
        pointer_id
      );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data &&
        response?.data?.response?.data?.employs &&
        response?.data?.response?.data?.applicant_data

      ) {
        const employmentVerificationData = response?.data?.response?.data?.employs;
        setApplicantdata(response?.data?.response?.data?.applicant_data);
        setOriginalData(employmentVerificationData);
        setCurrentPageData(
          employmentVerificationData.slice(0, parseInt(dataPerPageButton))
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getEmploymentVerficationFunction();
  }, []);

  const handleBack = () => {
    navigate("/user/employment_verification_pending");
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };
  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  useEffect(() => {
    if (searchQuery === "") {
      setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
    } else {
      const filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));

      // Check if filtered data is empty and update state accordingly
      if (filteredData.length === 0) {
        setShowNoDataMessage(true);
      } else {
        setShowNoDataMessage(false);
      }
    }
  }, [searchQuery, dataPerPageButton, originalData]);

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Employment Verification Pending" />
      </Row>

      {/* {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}


{loading && (
 <Loader />
      )}

      <div className="incomplete-info">
        <Card className="shadow forgot-card-identification">
          <Card.Body>

















          <Row>
              <Col md={6}>
                <div className="table-container">
                  <Table className="custom-tableData_em">
                    <tbody>
                      <tr>
                        <td className="bold-green">PRN</td>
                        <td className="bold-green">
                       : {Applicantdata?.prn}
                        </td>
                      </tr>
                      <tr>
                        <td className="bold-green">Application No.</td>
                        <td className="normal-greeenn-text">
                         : {Applicantdata?.unique_code}
                        </td>
                      </tr>

                      <tr>
                        <td className="bold-green">Applicant's Name</td>
                        <td className="normal-greeenn-text">
                         : {Applicantdata?.fullname}
                        </td>
                      </tr>

             
                    </tbody>
                  </Table>
                </div>
              </Col>
              {/* <Col md={4}>
                <img
                //   src={ApplicationDetails?.user_profile_link || backgroundImage}
                  alt="User Profile"
                  className="img-fluid"
                  style={{ height: "250px", border: "2px solid #ffcc01" }}
                />
              </Col> */}
            </Row>











            <hr className="mb-3 mt-1 thick-hr" />







            <div className="account-details">
              <div id="table_wrapper" className="dataTables_wrapper no-footer">
                <div
                  style={{ display: "flex", justifyContent: "space-between",cursor:"pointer" }}
                >
                  <div className="dataTables_length">
                    <label>
                      <select
                        value={dataPerPageButton}
                        onChange={handleDataPerPageChange}
                        style={{cursor:"pointer" }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>

                  <div id="table_filter" className="dataTables_filter">
                    <label>
                      <i className="fa fa-search"></i>
                      <input
                        type="search"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                </div>

                {originalData.length === 0 && !loading && (
                  <div className="no-data-message">
                    <p className="oops-message">Oops! No data to display.</p>
                  </div>
                )}

                {showNoDataMessage && (
                  <div className="no-data-message">
                    <p className="oops-message">
                      Oops! No relevant results found.
                    </p>
                  </div>
                )}

                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                      <table className="table table-striped table-hover dataTable no-footer">
      <thead>
        <tr>
          <th scope="col" className="col-1">Sr. No.</th>
          <th scope="col" className="col-2">Referee Name</th>
          <th scope="col" className="col-3">Referee Email ID</th>
          <th scope="col" className="col-2">Referee Contact No.</th>
          <th scope="col" className="col-3">Company / Organisation Name</th>
          <th scope="col" className="col-1">Status</th>
        </tr>
      </thead>
      <tbody>
        {currentPageData.map((item, index) => (
          <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
            <td><strong>{index + 1}</strong></td>
            <td>{item?.referee_name}</td>
            <td>{item?.referee_email}</td>
            <td>{item?.ref_mobile_code}</td>
            <td>{item?.company_organisation_name}</td>

            <td>
                        <span
                          className={
                            item?.status === "Pending"
                              ? "pending-status-buttonnnnn"
                              : "verified-status-buttonnnnn"
                          }
                        >
                          {item?.status}
                        </span>
                      </td>


          </tr>
        ))}
      </tbody>
    </table>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-3 thick-hr" />

                <div className="pagination-container">
                  <div className="dataTables_info">
                    Showing{" "}
                    {currentPage === 1
                      ? 1
                      : (currentPage - 1) * dataPerPageButton + 1}{" "}
                    to{" "}
                    {Math.min(
                      currentPage * dataPerPageButton,
                      originalData.length
                    )}{" "}
                    of {originalData.length} entries
                  </div>

                  <div className="pagination-buttons">
                    <button
                      className={`paginate_button ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={currentPage === 1}
                      style={
                        currentPage === 1
                          ? { pointerEvents: "none", cursor: "not-allowed" }
                          : {}
                      }
                    >
                      Previous
                    </button>

                    <button className="paginate_button">{currentPage}</button>

                    <button
                      className={`paginate_button ${
                        currentPage ===
                        Math.ceil(originalData.length / dataPerPageButton)
                          ? "disabled"
                          : ""
                      }`}
                      onClick={handleNext}
                      disabled={
                        currentPage ===
                        Math.ceil(originalData.length / dataPerPageButton)
                      }
                      style={
                        currentPage ===
                        Math.ceil(originalData.length / dataPerPageButton)
                          ? { pointerEvents: "none", cursor: "not-allowed" }
                          : {}
                      }
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Logout"
              message="Are you sure you want to log out?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default ViewEmploymentVerificationPending;
