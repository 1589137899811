import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { Button, Col, Row, Table } from "react-bootstrap";
import "./Documentary Evidence.css";
import ConfirmationModal from "../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import {
  stage2_add_employment_details,
  getAllCountryDataAPI,
  getEmploymentDetailsForTable_stage2_API,
} from "../../../api";
import { useLocation } from "react-router-dom";
import Heading from "../../Heading/Heading";
import NavigationButtons from "../../Navigation Buttons/Navigation Buttons";
import EmploymentTable from "./Employment Table/Employment Table";
import Loader from "../../Loader/Loader";

const DocumentaryEvidence = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [countriesData, setCountriesData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const getEmploymentDataFunction = async () => {
    try {
      setLoading(true);
      const response = await getEmploymentDetailsForTable_stage2_API(
        pointerData?.pointer_id
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data &&
        response?.data?.response?.data?.add_employment_TB
      ) {
        const employData = response?.data?.response?.data?.add_employment_TB;

        setOriginalData(employData);

        if (employData.length > 0) {
          setAddedSuccessfully(true);
        }
      } else {
        console.error(
          "Error fetching occupations data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };

  useEffect(() => {
    getEmploymentDataFunction();
  }, []);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [addedSuccessfully, setAddedSuccessfully] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectEmploymentType, setselectEmploymentType] = useState("");
  const [companyNameOrOrganisation, setCompanyNameOrOrganisation] =
    useState("");
  const [enterRefereeName, setEnterRefereeName] = useState("");
  const [refreeMobileCountryCode, setRefreeMobileCountryCode] = useState("13");
  const [refreeMobileNumber, setRefreeMobileNumber] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [confirmRefereeEmail, setConfirmRefereeEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [CheckMail, setCheckMail] = useState(false);

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/view_application", {
      state: { pointerData },
    });
    toast.success("Navigated to View Application.");
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input) && input !== "") {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleConfirmEmailChange = (e) => {
    const value = e.target.value;
    setConfirmRefereeEmail(value);
    setConfirmEmailError(false);
    setCheckMail(true);
  };

  useEffect(() => {
    if (confirmRefereeEmail === "") {
      // If confirmRefereeEmail is empty, clear the error
      setConfirmEmailError(false);
    } else if (confirmRefereeEmail !== email) {
      // If confirmRefereeEmail does not match email, set error
      setConfirmEmailError(true);
    } else {
      // If confirmRefereeEmail matches email, clear the error
      setConfirmEmailError(false);
    }
  }, [confirmRefereeEmail, email, CheckMail]);

  const handleNextPage = async (e) => {
    e.preventDefault();

    if (addedSuccessfully === false) {
      toast.warning("Please add at least one Employment Detail.");
      return;
    }

    if (originalData.length === 0) {
      toast.warning("Please add at least one Employment Detail.");
      return;
    }

    if (addedSuccessfully === true) {
      if (!termsChecked) {
        toast.error("Agree to the terms.");
        document.getElementById("termsAndConditions").focus();
        return;
      }
      navigate("/user/stage_2/documentary_evidence/upload_documents", {
        state: { pointerData: pointerData },
      });
    }
  };

  const handleNextSubmit = async (e) => {
    e.preventDefault();

    if (companyNameOrOrganisation.trim() === "") {
      toast.error("Please enter your Company/Organisation Name.");

      document.getElementById("companyNameOrOrganisationInput").focus();
      return;
    }

    if (selectEmploymentType.trim() === "") {
      toast.error("Please select your Employment Type.");
      document.getElementById("selectEmploymentTypeSelect").focus();
      return;
    }

    if (enterRefereeName.trim() === "") {
      toast.error("Please enter your Referee Name.");
      document.getElementById("enterRefereeNameInput").focus();
      return;
    }

    if (refreeMobileCountryCode.trim() === "") {
      toast.error("Please select your Referee country code.");
      document.getElementById("refreeMobileCountryCode").focus();
      return;
    }

    if (refreeMobileNumber.trim() === "") {
      toast.error("Please enter your Referee Contact number.");
      document.getElementById("refreeMobileNumber").focus();
      return;
    }

    // if (email.trim() === "") {
    //   toast.error("Please enter your Referee Email.");
    //   document.getElementById("refereeEmailInput").focus();
    //   return;
    // }

    // if (confirmRefereeEmail.trim() === "") {
    //   toast.error("Please confirm your Referee Email.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   return;
    // }

    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // if (!emailPattern.test(email.trim())) {
    //   toast.error("Please enter a valid Referee Email address.");
    //   document.getElementById("refereeEmailInput").focus();
    //   return;
    // }

    // if (!emailPattern.test(confirmRefereeEmail.trim())) {
    //   toast.error("Please enter a valid Confirm Referee Email address.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   return;
    // }

    // if (email.trim() !== confirmRefereeEmail.trim()) {
    //   toast.error("Emails don't match.");
    //   document.getElementById("confirmRefereeEmailInput").focus();
    //   setConfirmEmailError(true);
    //   return;
    // }

    if (email.trim() === "") {
      toast.error("Please enter your Referee Email.");
      document.getElementById("refereeEmailInput").focus(); // This ID needs to match
      return;
    }

    if (confirmRefereeEmail.trim() === "") {
      toast.error("Please confirm your Referee Email.");
      document.getElementById("confirmRefereeEmailInput").focus(); // This ID needs to match
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email.trim())) {
      toast.error("Please enter a valid Referee Email address.");
      document.getElementById("refereeEmailInput").focus();
      return;
    }

    if (!emailPattern.test(confirmRefereeEmail.trim())) {
      toast.error("Please enter a valid Confirm Referee Email address.");
      document.getElementById("confirmRefereeEmailInput").focus();
      return;
    }

    if (email.trim() !== confirmRefereeEmail.trim()) {
      toast.error("Emails don't match.");
      document.getElementById("confirmRefereeEmailInput").focus();
      setConfirmEmailError(true);
      return;
    }

    // if (!termsChecked) {

    //   toast.error("Agree to the terms.");
    //   document.getElementById("termsAndConditions").focus();
    //   return;

    // }

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("company_organisation_name", companyNameOrOrganisation);
    formData.append("employment_type", selectEmploymentType);
    formData.append("referee_name", enterRefereeName);
    formData.append("referee_mobile_number_code", refreeMobileCountryCode);
    formData.append("referee_mobile_number", refreeMobileNumber);
    formData.append("referee_email", email);
    formData.append("Confirm_referee_email", confirmRefereeEmail);

    // API call to save Employment Details
    try {
      setLoading(true);

      const response = await stage2_add_employment_details(
        formData,
        pointerData?.pointer_id
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        setAddedSuccessfully(true);
        toast.success("Employment Details saved successfully.");
        getEmploymentDataFunction();

        // navigate("/user/stage_2/documentary_evidence/upload_documents", {
        //   state: { pointerData },
        // });

        setCompanyNameOrOrganisation("");
        setselectEmploymentType("");
        setEnterRefereeName("");
        setRefreeMobileCountryCode("13");
        setRefreeMobileNumber("");
        setEmail("");
        setConfirmRefereeEmail("");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Employment Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Employment Details:", error);
      toast.error("Failed to save Employment Details");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = () => {
    // navigate("/user/dashboard");

    navigate("/user/view_application", {
      state: { pointerData },
    });

    setShowConfirmationModal(false);
    toast.success("Employment Details saved successfully.");
  };

  const fakeSubmit = (e) => {
    e.preventDefault();
  };

  const handleSaveAndExit = () => {
    setShowConfirmationModal(true);
  };

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const [
    showConfirmationBackTodashboardModal,
    setShowConfirmationBackTodashboardModal,
  ] = useState(false);
  const [backtodashboardButtonClicked, setBackToDashboardButtonClicked] =
    useState(false);

  const handleConfirmationBackToDashboardNo = () => {
    setShowConfirmationBackTodashboardModal(false);
  };

  const handleConfirmationBackToDashboardYes = () => {
    // navigate("/user/dashboard");

    navigate("/user/view_application", {
      state: { pointerData },
    });
    setShowConfirmationBackTodashboardModal(false);
    toast.success("Navigated to View Application.");
  };

  const handleBackToView = () => {

    navigate("/user/view_application", {
      state: { pointerData },
    });
    toast.success("Navigated to View Application.");

    // setBackToDashboardButtonClicked(true);
    // setShowConfirmationBackTodashboardModal(true);
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBackToView}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to View Application
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Stage 2 - Documentary Evidence" />
      </Row>

      <div className="documentary-em-container-contact-details">
        <Card className="shadow documentary-em-card-contact-details">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Add Employment Details</b>
          </div>

          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-1 mt-1">
                  Application No. :{" "}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                    {/* (T.B.A.) */}
                  </b>
                </div>
              </div>
            </div>

            {/* Employment Details */}

            <Card.Body>
              <Form onSubmit={fakeSubmit}>
                <div
                  className="rounded p-2 mb-4"
                  style={{ background: "#ffcc01" }}
                >
                  <h4 className="mb-2">Instructions :</h4>
                  <ul className="list-unstyled">
                    <li className="mb-1" style={{ color: "#000" }}>
                      &#8226; Only include Employment related to your nominated
                      occupation.
                    </li>
                    <li className="mb-0" style={{ color: "#000" }}>
                      &#8226; Only include Employment for which you will be
                      submitting supporting documents.
                    </li>
                  </ul>
                </div>

                <div>
                  <Form onSubmit={handleNextSubmit}>
                    <div className="row mb-0">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="companyNameOrOrganisationInput">
                            ▸ Company / Organisation Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyNameOrOrganisationInput"
                            name="companyNameOrOrganisationInput"
                            placeholder="Enter Company / Organisation Name"
                            value={companyNameOrOrganisation}
                            onChange={(e) =>
                              setCompanyNameOrOrganisation(e.target.value)
                            }
                            style={{ height: "40px" }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicSurnamePostal">
                          <Form.Label htmlFor="selectEmploymentTypeSelect">
                            ▸ Employment Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="form-group">
                            <select
                              className="form-select"
                              id="selectEmploymentTypeSelect"
                              name="selectEmploymentType"
                              value={selectEmploymentType}
                              onChange={(e) =>
                                setselectEmploymentType(e.target.value)
                              }
                              style={{ height: "40px", cursor: "pointer" }}
                            >
                              <option value="" disabled>
                                Select Employment Type
                              </option>
                              <option>Full time/ Part time/ Casual</option>
                              <option>Self employed</option>
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mb-0 mt-0">
                      <div className="col-md-6 mb-2">
                        <Form.Group controlId="formBasicStreetPostal">
                          <Form.Label htmlFor="enterRefereeNameInput">
                            ▸ Referee Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <input
                            type="text"
                            className="form-control"
                            id="enterRefereeNameInput"
                            name="enterRefereeName"
                            placeholder="Enter Referee Name"
                            value={enterRefereeName}
                            onChange={(e) =>
                              setEnterRefereeName(e.target.value)
                            }
                            style={{ height: "40px" }}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicMobileEmergency">
                          <Form.Label>
                            ▸ Referee Contact No.{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <select
                                  id="refreeMobileCountryCode"
                                  className="form-select"
                                  style={{ height: "40px", cursor: "pointer" }}
                                  value={refreeMobileCountryCode}
                                  onChange={(e) =>
                                    setRefreeMobileCountryCode(e.target.value)
                                  }
                                >
                                  <option value="">Select Country Code</option>
                                  {/* Map over countries to generate options */}
                                  {countriesData.map((country) => (
                                    <option
                                      key={country.id}
                                      className="dropdown-options"
                                      value={country.id}
                                    >
                                      {country.name} (+{country.phonecode})
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                {/* <label htmlFor="refreeMobileNumber">
                                  ▸ Phone Number
                                </label> */}
                                <input
                                  type="number"
                                  className="form-control"
                                  id="refreeMobileNumber"
                                  name="refreeMobileNumber"
                                  placeholder="Enter Referee Phone Number"
                                  style={{ height: "40px" }}
                                  value={refreeMobileNumber}
                                  onChange={(e) =>
                                    setRefreeMobileNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    {/* <div className="row mb-0">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="refereeEmailInput">
                            ▸ Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="refereeEmailInputttt"
                            name="refereeEmailInputttt"
                            placeholder="Enter Referee Email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ height: "40px" }}
                          />
                        </div>

                        {emailError && (
                          <p className="text-danger">{emailError}</p>
                        )}
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicConfirmRefereeEmail">
                          <Form.Label htmlFor="confirmRefereeEmailInput">
                            ▸ Confirm Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="form-group">
                            <input
                              type="text"
                              className={`form-control ${
                                CheckMail && confirmEmailError
                                  ? "is-invalid"
                                  : ""
                              }`}
                              // className={`form-control ${
                              //   confirmEmailError ? "is-invalid" : ""
                              // }`}
                              id="confirmRefereeEmailInputiii"
                              name="confirmRefereeEmailInputiii"
                              placeholder="Confirm Referee Email"
                              value={confirmRefereeEmail}
                              onChange={handleConfirmEmailChange}
                              style={{ height: "40px" }}
                            />
                            {confirmEmailError && CheckMail && (
                              <p className="text-danger">Emails do not match</p>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    </div> */}

                    <div className="row mb-0">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="refereeEmailInput">
                            ▸ Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="refereeEmailInput" // Correct ID
                            name="refereeEmailInput"
                            placeholder="Enter Referee Email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ height: "40px" }}
                          />
                        </div>

                        {emailError && (
                          <p className="text-danger">{emailError}</p>
                        )}
                      </div>

                      <div className="col-md-6">
                        <Form.Group controlId="formBasicConfirmRefereeEmail">
                          <Form.Label htmlFor="confirmRefereeEmailInput">
                            ▸ Confirm Referee Email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <div className="form-group">
                            <input
                              type="text"
                              className={`form-control ${
                                CheckMail && confirmEmailError
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="confirmRefereeEmailInput" // Correct ID
                              name="confirmRefereeEmailInput"
                              placeholder="Confirm Referee Email"
                              value={confirmRefereeEmail}
                              onChange={handleConfirmEmailChange}
                              style={{ height: "40px" }}
                            />
                            {confirmEmailError && CheckMail && (
                              <p className="text-danger">Emails do not match</p>
                            )}
                          </div>
                        </Form.Group>
                      </div>
                    </div>

                    {/* <hr className="mb-2 mt-1 thick-hr" /> */}
                  </Form>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleNextSubmit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      transition: "background-color 0.2s, color 0.2s",
                      width: "150px",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Add
                  </button>
                </div>

                <hr className="mb-2 mt-1 thick-hr" />

                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
 */}

                {loading && <Loader />}

                {addedSuccessfully && originalData.length > 0 && (
                  <EmploymentTable
                    originalData={originalData}
                    getEmploymentDataFunction={getEmploymentDataFunction}
                    pointer_id={pointerData?.pointer_id}
                  />
                )}

                <div
                  className="form-group form-check mb-1"
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="termsAndConditions"
                    name="termsAndConditions"
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                    style={{
                      transform: "scale(1.3)",
                      border: "1px solid #055837",
                      color: "#055837",
                      backgroundColor: termsChecked ? "#055837" : "",
                      cursor: "pointer",
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditions"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                  >
                    I have finished adding employers.
                  </label>
                </div>

                {/* Back and Save & Exit and Next Buttons */}

                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Back Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleBack}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                    >
                      Back
                    </button>

                    {/* Save & Exit Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveAndExit}
                      style={{
                        backgroundColor: "#055837",
                        color: "#ffcc01",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      disabled={originalData.length === 0}
                    >
                      Save & Exit
                    </button>

                    {/* Next Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleNextPage}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                      disabled={originalData.length === 0}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the view application."
          />

          {backtodashboardButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackTodashboardModal}
              onHide={handleConfirmationBackToDashboardNo}
              onConfirm={handleConfirmationBackToDashboardYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the View Application page?"
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Logout"
              message="Are you sure you want to log out?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default DocumentaryEvidence;
